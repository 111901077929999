import React, { useContext, useEffect } from 'react';
import { Layout, Filters, ProductsGrid } from 'components';
import { Banner } from '../components/Banner';
import queryString from 'query-string';
import { navigate, useLocation } from '@reach/router';
import ProductContext from 'context/ProductContext';
import styled from 'styled-components';

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const EmptySearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export default function AllProducts() {
  const { products, collections, selectCountry, currentTranslate } = useContext(
    ProductContext
  );
  const bannerCollection = collections.find(
    collection => collection.title === 'Banners Internos'
  );
  const collectionProductsMap = {};
  const collectionCountrysMap = {};
  const { search } = useLocation();
  const qs = queryString.parse(search);

  const selectedCollectionsIds = qs.c?.split(',').filter(c => !!c) || [];
  const selectedCollectionIdsMap = {};

  const selectedCountries = [selectCountry];
  const selectedCountriesMap = {};

  const searchTerm = qs.s;
  const country = qs.country;

  selectedCollectionsIds.forEach(collectionId => {
    selectedCollectionIdsMap[collectionId] = true;
  });

  selectedCountries.forEach(collectionId => {
    selectedCountriesMap[collectionId] = true;
  });

  useEffect(() => {
    if (selectedCollectionsIds.length >= 1) {
      document.getElementById('filters').scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedCollectionsIds]);
  if (collections) {
    collections.forEach(collection => {
      collectionProductsMap[collection.shopifyId] = {};
      collectionCountrysMap[collection.shopifyId] = {};
      collection.products.forEach(product => {
        collectionCountrysMap[collection.shopifyId][product.shopifyId] = true;
        collectionProductsMap[collection.shopifyId][product.shopifyId] = true;
      });
    });
  }
  const filterByCategory = product => {
    if (Object.keys(selectedCollectionIdsMap).length) {
      for (let key in selectedCollectionIdsMap) {
        if (collectionProductsMap[key]?.[product.shopifyId]) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const filterBySearchTerm = product => {
    if (searchTerm) {
      return (
        product.title
          .toLocaleLowerCase()
          .indexOf(searchTerm.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const clearFilters = () => {
    navigate(`/productos?country=${country}`);
  };

  const filterByCountry = product => {
    if (Object.keys(selectedCountriesMap).length) {
      for (let key in selectedCountriesMap) {
        if (collectionCountrysMap[key]?.[product.shopifyId]) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const filteredProducts = products
    .filter(filterByCountry)
    .filter(filterByCategory)
    .filter(filterBySearchTerm);

  return (
    <Layout home={true}>
      <ProductGrid>
        <Banner id={bannerCollection.shopifyId} interno={true} />
        <Content id="content">
          <Filters clearFilters={clearFilters} />
          {!filteredProducts.length && (
            <EmptySearch>
              {currentTranslate.noResults.message}
              {searchTerm && (
                <>
                  &nbsp;
                  <strong>'{searchTerm}'</strong>
                </>
              )}
            </EmptySearch>
          )}
          {!!filteredProducts.length && (
            <div>
              <ProductsGrid products={filteredProducts} />
            </div>
          )}
        </Content>
      </ProductGrid>
    </Layout>
  );
}
